<template>
  <div class="animated" v-if="patron">
    <b-row>
      <b-col sm="12" md="6">
        <b-card>
          <div slot="header">
            <i class="fa fa-check"></i> Level configuration
          </div>
          <div v-if="loading">
            <div class="sk-circle-fade float-left">
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
            </div>
            <h2 class="float-left ml-4">Loading levels...</h2>
          </div>
          <div v-else>
            <h3 class="subtitle">Level configuration</h3>
            <p>
              Levels are a way to reward your members for being active, either
              by awarding them roles for hitting milestone levels or just for
              the bragging rights. Most aspects of leveling can be configured to
              fit your server's needs.
            </p>
            <hr />
            <h5 class="tinytitle">Level message</h5>
            <p>
              The message that gets sent when a member levels up.
              <code>{user} {lvl} {mention}</code> as well as most of tagscript
              is supported.
            </p>
            <b-input-group>
              <b-form-textarea
                :maxlength="2000"
                rows="6"
                type="text"
                placeholder="Enter a level up message"
                class="col-12"
                v-model="lvl_message"
              ></b-form-textarea>
            </b-input-group>
            <hr />
            <h5 class="tinytitle">Announcement destination</h5>
            <p>
              Announcements can be disruptive to conversations, pick a
              destination that fits your server.
            </p>
            <multiselect
              v-model="lvl_destination"
              id="discord_channel_select"
              track-by="id"
              label="name"
              placeholder="Please select a channel"
              open-direction="bottom"
              :clear-on-select="false"
              :close-on-select="true"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="false"
              :options="announcementDestinations"
              :allow-empty="false"
              group-values="channels"
              group-label="category"
              :group-select="false"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <font-awesome-icon style="color:#72767d" icon="hashtag" />
                  <span class="option__title ml-2">{{
                    props.option.name
                  }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    <font-awesome-icon
                      style="color:#72767d"
                      :icon="
                        !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                      "
                    />
                    {{ props.option.name || props.option.$groupLabel.name }}
                  </span>
                </div>
              </template>
            </multiselect>
            <hr />
            <h5 class="tinytitle">
              Only announce rewards
              <c-switch
                id="lvl_reward_only_switch"
                color="primary"
                class="float-right"
                label
                variant="pill"
                v-bind="labelIcon"
                v-model="lvl_rewardonly"
              />
            </h5>
            <label for="lvl_reward_only_switch" class="col-form-label"
              >If enabled, only level ups that award roles are announced.</label
            >
            <hr />
            <h5 class="tinytitle">
              Replace rewards
              <c-switch
                id="lvl_replace_switch"
                color="primary"
                class="float-right"
                label
                variant="pill"
                v-bind="labelIcon"
                v-model="lvl_replace"
              />
            </h5>
            <label for="lvl_replace_switch" class="col-form-label"
              >If enabled, old rewards are replaced by new ones, as opposed to
              being additive.</label
            >
            <hr />
            <h5 class="tinytitle">Rate limit</h5>
            <p>
              While activity is nice, spam isn't. With this you can configure
              how often you're able to receive xp from sending messages.
            </p>
            <b-row>
              <b-input-group class="col-sm-6 pr-sm-1 my-1">
                <b-form-input
                  type="number"
                  placeholder="0"
                  min="1"
                  max="25"
                  v-on:keydown="handleNonDecimalInput"
                  v-model="ratelimit_rate"
                ></b-form-input>
                <b-input-group-append>
                  <b-input-group-text class="mr-1"
                    >{{
                      ratelimit_rate == 1 ? "Message" : "Messages"
                    }}
                    per</b-input-group-text
                  >
                </b-input-group-append>
              </b-input-group>
              <b-input-group class="col-sm-6 pl-sm-1 my-1">
                <b-form-input
                  type="number"
                  placeholder="0"
                  min="1"
                  max="600"
                  v-on:keydown="handleNonDecimalInput"
                  v-model="ratelimit_window"
                ></b-form-input>
                <b-input-group-append>
                  <b-input-group-text class="mr-1">{{
                    ratelimit_window == 1 ? "Second" : "Seconds"
                  }}</b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-row>
            <hr />
            <h5 class="tinytitle">Level modulo</h5>
            <p>
              One common complaint regarding bots with levels is that they spam
              you. To combat this, the bot allows you to only announce level ups
              if they are evenly divisible by a number. For instance, setting
              this number to 5 would announce levels 5, 10, 15 etc.
            </p>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                autocomplete="1"
                v-model="lvl_modulo"
                min="1"
                max="100"
                v-on:keydown="handleNonDecimalInput"
                maxlength="2"
              ></b-form-input>
            </b-input-group>
            <p>Your first 5 announced levels are: {{ first_five }}</p>
            <hr />
            <h5 class="tinytitle">Minimum level</h5>
            <p>
              Because the xp required for each levels increases dramatically, it
              might be appealing to only announce levels after a certain point.
              This setting is
              <i>inclusive</i>.
            </p>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                autocomplete="1"
                v-model="lvl_minimum"
                min="0"
                max="100"
                v-on:keydown="handleNonDecimalInput"
                maxlength="2"
              ></b-form-input>
            </b-input-group>
            <hr />
            <h5 class="tinytitle">Blacklist</h5>
            <p>
              Blacklist roles or channels from being awarded/awarding xp. Useful
              for preventing people spamming commands in order to gain xp.
            </p>
            <b-form-group
              label="Do not award xp for messages sent in any of these channels"
              label-for="lvl_bl_channel"
            >
              <multiselect
                v-model="lvl_bl_channel"
                :options="groupedChannels"
                group-values="channels"
                group-label="category"
                :group-select="false"
                :multiple="true"
                label="name"
                track-by="id"
                id="lvl_bl_channel"
              >
                <template slot="tag" slot-scope="{ option, search, remove }">
                  <span
                    class="multiselect__tag channelpicker__tag"
                    :style="{ borderColor: '#eee' }"
                  >
                    <i
                      aria-hidden="true"
                      tabindex="1"
                      @keydown.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon channelpicker_icon"
                      :style="{ backgroundColor: '#eee' }"
                    ></i>
                    <font-awesome-icon
                      style="color:#999;margin-right:3px;"
                      icon="hashtag"
                    />
                    <span class="defaultcursor" v-text="option.name"></span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      <font-awesome-icon
                        style="color:#72767d"
                        :icon="
                          !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                        "
                      />
                      {{ props.option.name || props.option.$groupLabel.name }}
                    </span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Blacklist members with any of these roles from receiving xp"
              label-for="lvl_bl_role"
            >
              <multiselect
                v-model="lvl_bl_role"
                :options="roles"
                :multiple="true"
                label="name"
                track-by="id"
                id="lvl_bl_role"
              >
                <template slot="tag" slot-scope="{ option, search, remove }">
                  <span
                    class="multiselect__tag"
                    :style="{
                      'border-color': option
                        ? '#' + option.color.toString(16)
                        : '#eeaaee'
                    }"
                  >
                    <i
                      aria-hidden="true"
                      tabindex="1"
                      @keydown.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon fakediscord"
                      :style="{
                        'background-color':
                          '#' + intToHex(option.color || 12172222)
                      }"
                    ></i>
                    <span class="defaultcursor" v-text="option.name"></span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div
                    :style="{ color: parseColor(props.option) }"
                    class="option__desc"
                  >
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <div slot="footer">
              <b-button
                variant="primary"
                class="float-right"
                v-on:click="save_settings"
                >Save</b-button
              >
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col sm="12" md="6">
        <b-card v-if="!loading">
          <div slot="header"><i class="fa fa-check"></i> Level rewards</div>
          <b-row>
            <b-col cols="6">
              <multiselect
                v-model="temp_role"
                id="temp_role"
                track-by="id"
                label="name"
                placeholder="Please select a role"
                open-direction="bottom"
                :searchable="true"
                :clear-on-select="false"
                :close-on-select="true"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="false"
                :options="non_managed_roles"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <font-awesome-icon style="color:#72767d" icon="user-tag" />
                    <span class="option__title ml-2">{{
                      props.option.name
                    }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span
                      class="option__title"
                      :style="{ color: '#' + intToHex(props.option.color) }"
                      >{{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </b-col>
            <b-col cols="4">
              <b-input-group prepend="lvl">
                <b-form-input
                  type="number"
                  placeholder="0"
                  min="1"
                  max="600"
                  v-on:keydown="handleNonDecimalInput"
                  v-model="temp_level"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="2">
              <b-button
                block
                variant="success"
                style="height:33px"
                v-on:click="add_reward"
              >
                <font-awesome-icon icon="plus" class="mr-1" />Add
              </b-button>
            </b-col>
          </b-row>
          <hr />
          <v-client-table
            :columns="columns"
            :data="data"
            :options="options"
            :theme="theme"
            id="dataTable"
            style="width: 100%; margin: none"
          >
            <b-button
              slot="options"
              class="d-md-inline-block"
              slot-scope="props"
              block
              variant="outline-danger"
              v-on:click="delete_reward(props)"
            >
              Delete
              <i class="icon-trash"></i>
            </b-button>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
  </div>

  <div v-else>
    <PatronCard :patron="patron" :add_premium="add_premium" />
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { ClientTable, Event } from "vue-tables-2";
import Multiselect from "vue-multiselect";
import PatronCard from "@/views/oc/PatronCard";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({
  types: toastTypes,
  timeout: 5000,
  style: {
    "mini-toastr__notification": {
      "margin-top": "200px"
    }
  }
});

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);
Vue.use(ClientTable);

export default {
  name: "DataTable",
  components: {
    Event,
    ClientTable,
    cSwitch,
    Multiselect,
    PatronCard
  },
  notifications: {
    saved_settings: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Settings saved"
    },
    added_reward: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Reward added"
    },
    deleted_reward: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Reward deleted"
    },
    fill_in_fields: {
      type: VueNotifications.types.warn,
      title: "Uh oh",
      message: "Please fill in all fields"
    },
    no_spaces_thank_you: {
      type: VueNotifications.types.warn,
      title: "Bad tag",
      message:
        "Tags don't really work when they have spaces added, consider replacing them with dashes or underscores"
    }
  },
  data: function() {
    return {
      columns: ["role", "lvl", "options"],
      data: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      patron: true,
      lvl_message: "",
      lvl_modulo: 1,
      lvl_minimum: 0,
      channels: [],
      roles: [],
      lvl_bl_channel: [],
      lvl_bl_role: [],
      temp_role: null,
      temp_level: 1,
      loading: true,
      lvl_destination: null,
      lvl_rewardonly: false,
      lvl_replace: false,
      ratelimit_rate: 1,
      ratelimit_window: 60,
      options: {
        filterByColumn: true,
        columnsDisplay: {
          content: "not_mobile",
          uses: "not_mobile"
        },
        texts: {
          filter: "Filter",
          filterBy: "Filter by {column}"
        },
        headings: {
          name: "Name",
          code: "Content",
          uri: "View Record"
        },

        sortable: ["role", "lvl", "options"],
        filterable: ["role"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        },
        childRow: false,
        highlightMatches: true
      },
      theme: "bootstrap4"
    };
  },
  methods: {
    add_reward() {
      if (!this.temp_role || !this.temp_level) {
        this.fill_in_fields({
          message: "Please enter both a level and a role reward"
        });
        return;
      }
      if (this.data.find(d => d.lvl == this.temp_level)) {
        this.fill_in_fields({
          message:
            "You already have a reward for that level, please remove it first"
        });
        return;
      }
      let role = this.roles.find(x => x.id == this.temp_role);
      return this.axios
        .post("/api/v1/servers/" + this.$route.params.guild_id + "/levels", {
          level: this.temp_level,
          role_id: this.temp_role.id
        })
        .then(r => {
          if (r.status != 200) {
            this.fill_in_fields({
              message:
                "Oops, can't do that! you're probably trying to add a role above your own highest."
            });
            return;
          }
          this.data.push({ lvl: this.temp_level, role: this.temp_role.name });
          this.temp_role = null;
          this.temp_level = 1;
          return r.data;
        })
        .catch(e =>
          this.fill_in_fields({
            message: "Error! Something went wrong. Please try again later!"
          })
        );
    },
    delete_reward(row) {
      return this.axios
        .delete(
          "/api/v1/servers/" +
            this.$route.params.guild_id +
            "/levels/" +
            row.row.lvl
        )
        .then(r => {
          this.data.splice(row.index - 1, 1);
          this.deleted_reward();
        });
    },
    intToHex(int) {
      if (!int) {
        return "eee";
      }
      return int.toString(16);
    },
    parseColor(r) {
      return r.color != null ? "#" + r.color.toString(16) : "#fefefe";
    },
    save_settings() {
      return this.axios
        .put("/api/v1/servers/" + this.$route.params.guild_id + "/levels", {
          fmt: this.lvl_message,
          role_bl: (this.lvl_bl_role || []).map(r => r.id),
          channel_bl: (this.lvl_bl_channel || []).map(c => c.id),
          rate: parseInt(this.ratelimit_rate),
          time_window: parseInt(this.ratelimit_window),
          announce_mod: parseInt(this.lvl_modulo),
          announce_lower: parseInt(this.lvl_minimum),
          announce_destination: this.lvl_destination.id,
          replace_roles: this.lvl_replace,
          only_announce_rewards: this.lvl_rewardonly
        })
        .then(r => {
          this.saved_settings();
          return r.data;
        });
    },
    add_premium() {
      return this.axios
        .post("/api/v1/servers/" + this.$route.params.guild_id + "/patron", {})
        .then(r => {
          this.patron = true;
        });
    }
  },
  computed: {
    first_five() {
      let mini = parseInt(this.lvl_minimum || 1);
      let mod = parseInt(this.lvl_modulo || 1);

      let first =
        mini % mod ? mini + (mod - (mini % mod)) : Math.max(mod, mini);

      return [0, 1, 2, 3, 4].map(n => first + n * mod).join(", ");
    },
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    },
    non_managed_roles() {
      return this.roles.filter(
        r => !r.managed && r.id != this.$route.params.guild_id
      );
    },
    channelsWithOptions() {
      return [
        { name: "Do not send announcement", id: null, parent_id: null },
        { name: "Send announcement in same channel", id: -1, parent_id: null },
        { name: "DM announcement", id: -2, parent_id: null },
        ...this.channels
      ];
    },
    announcementDestinations() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channelsWithOptions.sort(
        (a, b) => a.position - b.position
      )) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
          continue;
        }
        currentGroup = groups.find(g => g.category.id === chn.parent_id);
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    }
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/levels")
      .then(r => {
        this.lvl_message = r.data.settings.fmt;
        this.lvl_modulo = r.data.settings.announce_mod || 1;
        this.lvl_minimum = r.data.settings.announce_lower || 1;
        this.ratelimit_rate = r.data.settings.rate;
        this.ratelimit_window = r.data.settings.time_window;
        this.channels = r.data.channels;
        let anno = r.data.settings.announce_destination || null;
        this.lvl_destination = [
          { name: "Do not send announcement", id: null },
          { name: "Send announcement in same channel", id: -1 },
          { name: "DM announcement", id: -2 },
          ...this.channels
        ].find(c => {
          return c.id == anno;
        });
        this.roles = r.data.roles;
        this.lvl_bl_channel = this.channels.filter(c =>
          (r.data.settings.channel_bl || []).includes(c.id)
        );
        this.lvl_bl_role = this.roles.filter(c =>
          (r.data.settings.role_bl || []).includes(c.id)
        );
        this.lvl_rewardonly = r.data.settings.only_announce_rewards;
        this.lvl_replace = r.data.settings.replace_roles;
        this.patron = r.data.patron;

        this.data = Object.entries(r.data.rewards).map(o => {
          let [lvl, role_id] = o;
          let role = this.roles.find(r => r.id == role_id) || {
            name: "Potentially deleted role?"
          };
          return { lvl: lvl, role: role.name };
        });
        this.loading = false;
        this.$Progress.finish();
      });
  }
};
</script>
<style lang="scss">
#dataTable {
  width: 95%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .vue-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
