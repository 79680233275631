<template>
  <b-card header="Become a patron">
    <div v-if="patron == false">
      <h4 class="smalltitle">Become a patron</h4>
      <p>Hey there, this feature is only available to patrons.</p>

      <a href="https://www.patreon.com/join/carlbot">
        <img
          alt="become a patron"
          src="@/../public/img/brand/BecomeAPatron.png"
        />
      </a>
      <p>For $5/month you gain access to:</p>
      <ul>
        <li>Levels</li>
        <li>Timed reaction roles</li>
        <li>1000 reaction role limit</li>
        <li>Immediate autofeeds</li>
        <li>Role mention toggle in short autofeeds</li>
        <li>Higher purge limits</li>
        <li>Voice-role links</li>
        <li>Multiple command blocks in a single tag</li>
        <li>Audit log fetching for bans in the modlog</li>
        <li>Autostar for starboard</li>
        <li>Your own role in the support server</li>
        <li>Access to new commands and features before the general public</li>
        <li>
          Last but not least: You're directly responsible for keeping the bot
          alive for tens of thousands of servers
        </li>
      </ul>
    </div>
    <div v-else>
      <h4 class="smalltitle">Hey there, patron</h4>
      <hr />
      <p>
        You're a patron (Thanks!), but this server is not marked as premium. I
        see you haven't used up all your premium servers. if you want to mark
        this server as premium, simply press the button below!
      </p>
      <b-button variant="success" v-on:click="add_premium"
        >Mark server as premium</b-button
      >
    </div>
  </b-card>
</template>

<script>
export default {
  name: "PatronCard",
  props: ["patron", "add_premium"]
};
</script>
